.crm-widgets-object {
    .ant-select-selection--multiple {
        padding-right: 30px;
    }
}
.crm-object-selector-modal {
    .antd-modal-mask {
        z-index: 10000;
    }
    .antd-modal-wrapper {
        z-index: 10000;
    }
    .ant-modal-header {
        border-bottom: none;
    }
    .ant-modal-body {
        padding-top: 0;
        padding-right: 10px;
        padding-left: 20px;
    }
    .ant-modal-title {
        padding-left: 4px;
    }
    .maimai-crm-table .ant-table-thead > tr > th .th-spliter {
        display: none;
    }
}
