.crm-customer-m-fetch {
    .ant-input-group-addon {
        padding: 0;
    }
    .outdatespin {
        .ant-spin-dot {
            width: 300px;
            font-size: 12px;
            left: 20% !important;
            opacity: 0.6;
            cursor: pointer;
        }
    }
}
