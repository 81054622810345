.component-searchable-tree-leafonly {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search {
        flex-grow: 0;
        flex-shrink: 0;
    }
    .tree {
        flex-grow: 1;
        flex-shrink: 1;
        overflow: auto;
    }
    .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background-color: transparent;
    }
    .invisible-tree-node {
        display: none;
    }

    li.ant-tree-treenode-disabled > span:not(.ant-tree-switcher),
    li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper,
    li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper span {
        color: initial;
        cursor: unset;
        pointer-events: none;
    }
}
