.crm-widgets-enum-input {
    .search {
    }
    .select-all {
        border-bottom: 1px solid #e6f1fc;
        padding: 6px 0;
        margin-bottom: 6px;
    }
    .options {
        overflow: auto;
        max-height: 160px;
        .checkbox-item {
            padding: 4px 0;
        }
        & > * {
            width: 100%;
        }
    }
}
