.log-filter-content {
    display: flex;
    flex-direction: row;
    margin: 20px 0;
    .log-filter-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 20px;
        &:first-of-type {
            margin-left: 0;
        }
        label {
            white-space: nowrap;
        }
    }
    .ant-btn {
        margin-left: 20px;
    }
}
