.delay-placeholder{
    height: 40px;
    margin-top: 16px;
}

.delay-form{
    position: absolute;
    top:40px;
    left: -150px;
    margin-top: 16px!important;
    .delay-form-input{
        width: 704px;
    }
}

.delay-tips{
    margin-top: -45px;
    margin-bottom: 65px;
    padding: 0px 15px;
    .delay-tip-content{
        margin: 8px 0;
        ul{
            list-style: none;
            padding: 0px;
            margin: 0px;
        } 
        
        ul li {
            list-style: none;
            padding: 0px;
            margin: 0px;
        }
    
        .delay-tip-content-header{
            font-weight: 500;
            margin-bottom: none!important;
        }
    }
}