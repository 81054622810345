.crm-customer-drop {
    .lint {
        font-weight: bolder;
        font-size: 1.2em;
    }
    .form-wrapper {
        padding: 10px 15px;
        background-color: #f7f8fa;
        .ant-form-item {
            margin-bottom: 12px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    .warning {
        margin-top: 10px;
    }
}
.crm-customer-drop.loading {
    pointer-events: none;
    opacity: 0.8;
}
