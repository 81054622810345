.nav-content {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    .crm-setting-tag {
        font-size: 18px;
        height: 28px;
        line-height: 28px;
        font-weight: 500;
        color: #0052ff;
        position: absolute;
        left: 138px;
        padding-left: 10px;
        border-left: 1px solid #0052ff;
    }
    .left-nav-content {
        display: flex;
        width: 100%;
        position: relative;
        height: 100%;
        align-items: center;
        justify-content: flex-start;
        .logo {
            width: 125px;
            height: 37px;
            margin-right: 127px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
        .inline-menu-bar {
            display: flex;
            flex-direction: row;
            height: 100%;
            min-width: 280px;
            line-height: 48px;
            font-size: 16px;
            font-weight: 500;
            a {
                color: #111;
            }
            .menu-bar-item {
                cursor: pointer;
                position: relative;
                margin-right: 48px;
                white-space: nowrap;
                .active-line {
                    display: none;
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 4px;
                    background-color: #0052ff;
                    border-radius: 2px;
                }
                &.active {
                    .active-line {
                        display: block;
                    }
                    color: #0052ff;
                }
            }
        }
    }
    .right-nav-content {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        position: relative;
        justify-content: flex-end;
        .search {
            .search-icon {
                width: 17px;
                height: 17px;
                margin-bottom: -2px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }
            .ant-input {
                background-color: #f4f5fa;
                &:placeholder-shown {
                    font-weight: 500;
                }
            }
        }
        .right-handle-bar {
            margin-left: 30px;
            white-space: nowrap;
            .ant-badge {
                display: inline;
                position: relative;
                .ant-badge-count {
                    padding: 5.3px;
                    transform: scale(0.75);
                    right: -9px;
                    top: -7px;
                    line-height: 10px;
                }
                .handle-icon {
                    margin: 0;
                }
            }
            .handle-icon {
                margin-right: 18px;
                cursor: pointer;
            }
        }
        .user-content {
            cursor: pointer;
            white-space: nowrap;
        }
    }
}
