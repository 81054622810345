.crm-widgets-date-range-input-fast-selectors {
    margin-top: 3px;
    padding: 6px;
    background-color: white;
}
.crm-widgets-date-range-input {
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    user-select: none;
    & > .calendar.left {
        margin-right: 10px;
    }
    & > .calendar.right {
        margin-left: 10px;
    }
    .ant-fullcalendar table {
        height: 190px;
    }

    // 如果给antd calendar一个undefined，样式依然是选中的，这里hack下
    .noselected {
        .ant-fullcalendar-selected-day .ant-fullcalendar-value,
        .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value {
            color: #000000;
            background-color: transparent;
        }
    }

    & > .calendar {
        flex-basis: 1;
        flex-grow: 1;
        .calendar-itself {
            background-color: white;
            padding: 8px;
            margin-top: 6px;
            .header {
                display: flex;
                justify-content: space-between;
                height: 40px;
                align-items: center;
                .left-icon {
                    margin-left: 15px;
                }
                .selector.year {
                    margin-left: 20px;
                }
                .right-icon {
                    margin-right: 15px;
                }
                .selector.month {
                    margin-right: 20px;
                }
                .arrow {
                    cursor: pointer;
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
            .time-selector {
                .ant-time-picker {
                    display: block;
                    width: initial;
                    margin: 0 15px;
                    margin-top: -7px;
                    margin-bottom: 5px;
                }
            }
        }
    }
}
