.crm-home-page-content {
    height: 100%;
    width: 100%;
    position: relative;
    min-width: 1200px;

    .ant-alert {
        font-size: 12px;
        background-color: #fff8c2;

        .ant-alert-icon {
            top: 11px;
            color: #4d2e0f;
        }

        .ant-alert-message {
            color: #4d2e0f;
        }
    }

    .crm-img-banner-content {
        display: flex;
        flex-direction: row;
        margin-bottom: 16px;
        margin-top: 16px;
        height: 80px;
        width: 100%;

        img {
            // width: 1024px;
            height: 80px;
        }

        .banner-yellow-space {
            flex: 1;
            height: 100%;
            background: #f42118;

            &.left-space {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }

            &.right-space {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                background: #f42118;
            }
        }

        .crm-img-banner {
            // width: 1024px;
            height: 80px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    }

    .home-page-title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 32px;
    }

    .crm-achievement-info {
        background-color: #fff;
        padding: 20px;
        border-radius: 12px;

        .achievement-body {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .achievement-item {
                // flex: 1;
                padding: 0 44px;
                cursor: pointer;

                .achievement-img {
                    width: 64px;
                    height: 64px;
                }

                &.opt {
                    .achievement-icon {
                        color: rgb(33, 162, 255);
                    }
                }

                &.contract {
                    .achievement-icon {
                        color: rgb(242, 89, 81);
                    }
                }

                &.noReturn {
                    .achievement-icon {
                        color: rgb(133, 82, 242);
                    }
                }

                &.return {
                    .achievement-icon {
                        color: rgb(26, 174, 88);
                    }
                }

                // .achievement-circle {
                //     width: 64px;
                //     height: 64px;
                //     border-radius: 50%;
                //     background-color: #25ACFF;
                //     text-align: center;
                //     line-height: 64px;
                //     font-size: 26px;
                //     color: #FFF;
                // }
                .achievement-icon {
                    display: inline;
                    font-size: 64px;
                    line-height: 64px;
                }

                .achievement-compare {
                    margin: 0;

                    .iconfont1 {
                        font-size: 12px;
                        margin: 0 2px;

                        &.up-icon {
                            color: #65c77e;
                        }

                        &.down-icon {
                            color: #f88383;
                        }
                    }
                }

                .achievement-text {
                    color: #6e727a;
                    font-size: 12px;
                    white-space: nowrap;

                    .text-highlight {
                        font-weight: 500;
                        font-size: 24px;
                        margin-left: 6px;
                        color: #15161f;

                        .small-size {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .crm-today-info {
            margin-top: 24px;
            font-size: 12px;
            color: #6e727a;

            .today-title {
                color: #15161f;
                font-weight: 500;
                margin-right: 30px;
            }

            .today-info-item {
                margin-right: 30px;
                cursor: pointer;

                .info-highlight {
                    color: #15161f;
                    font-weight: 500;
                }
            }
        }
    }

    .crm-home-process-info-wrapper {
        display: flex;
    }

    .crm-home-process-info {
        margin-top: 16px;
        background-color: #fff;
        padding: 20px;
        border-radius: 12px;

        .home-process-info-body {
            display: flex;
            flex-direction: row;

            .process-info-item {
                flex: 1;
                text-align: center;
                border-right: 1px solid #eeeff7;
                cursor: pointer;

                &:last-of-type {
                    border-right: none;
                }

                .process-info-label {
                    color: #6e727a;
                    font-weight: 500;
                }
            }
        }
    }

    .home-table-content {
        margin-top: 16px;
        display: flex;
        flex-direction: row;

        .table-item {
            height: 360px;
            width: 0;
            padding: 5px 15px;

            // 魔改标准布局
            .crm-standard-crud-outer {
                background: unset;
            }

            .crm-standard-crud .operation .filters {
                display: none;
            }

            .crm-standard-crud .operation .meta {
                padding-bottom: 0;
            }

            .maimai-crm-basic-table .base-pagi {
                position: relative;
                top: -8px;
            }

            .ant-table-placeholder {
                padding: 40px 16px;
            }
        }

        .home-page-title {
            margin-bottom: 15px;
        }

        .home-follow-schedule {
            flex: 1;
            background-color: #fff;
            border-radius: 8px;
            margin-right: 16px;
        }

        .home-opt-inprocess {
            flex: 1;
            background-color: #fff;
            border-radius: 8px;
        }
    }
}
