.lint-list-popover {
    .ant-popover-arrow {
        display: none;
    }
    .ant-popover-inner {
        position: relative;
        top: -10px;
        .ant-popover-inner-content {
            padding: 0;
            color: initial;
        }
    }
    .lint-list {
        max-height: 200px;
        overflow-y: auto;
        min-width: 120px;
        .ant-empty-normal {
            margin: 0;
            padding: 32px 0;
            color: #d1d3de;
        }
        .list-item {
            position: relative;
            display: block;
            padding: 5px 12px;
            overflow: hidden;
            color: rgba(0, 0, 0, 0.65);
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: pointer;
            -webkit-transition: background 0.3s ease;
            transition: background 0.3s ease;
            &:hover {
                background-color: #e6f7ff;
            }
        }
        .list-item.selected {
            color: rgba(0, 0, 0, 0.65);
            font-weight: 600;
            background-color: #fafafa;
            &:hover {
                background-color: #e6f7ff;
            }
        }
    }
}
