.crm-customer-drawer-content {
    .drawer-time-line-content {
        position: relative;
        padding: 12px;
        // padding-bottom: 36px;

        .drawer-time-line-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 16px;
            position: relative;

            .describe {
                flex: 1;
                padding-right: 12px;
                font-size: 12px;
                color: #afb1bc;
            }

            .delete {
                position: absolute;
                font-size: 12px;
                right: -12px;
                top: -14px;
                cursor: pointer;

                .delete-icon {
                    width: 32px;
                    height: 18px;
                }
            }

            .maimai-user-content {
                margin-right: 4px;
            }
        }

        .drawer-time-line-body {
            font-size: 12px;
            color: #6e727a;
            .customer-info-collapse {
                font-size: 12px;
                background-color: transparent;
                .title {
                    font-size: 12px;
                    font-weight: 500;
                }
                .ant-collapse-content-box {
                    padding: 0;
                }
            }
            .activity-record-item {
                display: flex;
                align-items: flex-start;
                .item-header {
                    width: 72px;
                    flex-shrink: 0;
                    text-align: right;
                    margin-right: 16px;
                    color: #6e727a;
                }
                .item-content {
                    flex: 1;
                    color: #15161f;
                    font-style: normal;
                    white-space: break-spaces;
                    line-break: anywhere;
                }
            }
            .follow-update-time {
                position: static;
                // right: 12px;
                // bottom: 8px;
                margin-top: 10px;
                text-align: right;
            }
            .new-photo-provider {
                // margin-top: 10px;
                display: flex;
                flex-wrap: wrap;
            }

            .icon-box {
                margin: 10px 0;
                text-align: right;
                .icon-box-item {
                    margin-right: 12px;
                    &.number {
                        color: #15161f;
                    }
                    &.iconfont {
                        font-size: 16px;
                        margin-right: 6px;
                        cursor: pointer;
                        &.active {
                            color: #0052ff;
                        }
                    }
                }
            }

            .follow-comment {
                padding: 5px 0;
                border-top: 1px #e4e5ed solid;
                border-collapse: collapse;
                color: #15161f;
                &:last-child {
                    border-bottom: 1px #e4e5ed solid;
                }
                .follow-comment-header {
                    font-weight: 500;
                    .follow-comment-header-time {
                        color: #6e727a;
                        margin-left: 5px;
                        font-size: 10px;
                        font-weight: normal;
                    }
                }
                .follow-comment-content {
                    font-size: 14px;
                }
            }

            .follow-placeholder {
                height: 20px;
            }

            .ant-input {
                border-top-left-radius: 16px;
                border-bottom-left-radius: 16px;
                width: calc(100% - 45px);
            }
            .ant-input-affix-wrapper {
                margin: 0 !important;
            }
            .ant-input-affix-wrapper .ant-input-suffix {
                right: 0px;
                .maimai-crm-button.ant-btn {
                    border-radius: 16px;
                    &:hover {
                        opacity: 1;
                    }
                }
            }

            .follow-show-history {
                color: #0052ff;
                cursor: pointer;
                padding-top: 5px;
                border-top: 1px #e4e5ed solid;
                margin-bottom: 10px;
                &:hover {
                    text-decoration: underline;
                }
            }

            em {
                color: #15161f;
                font-style: normal;
                white-space: break-spaces;
                line-break: anywhere;
            }

            .ant-input-group-addon {
                padding: 0px;
                border: none;
            }
        }
    }
}
