.page-process-list {
    [tabIndex] {
        height: initial !important;
    }

    .maimai-crm-basic-table.table-v-stretch {
        overflow: hidden;
    }
    .standard-drawer-form-wrapper .fixed-form-btns {
        top: 15px;
    }
}

.process-cat-dtitle2 {
    padding-bottom: 0px;
}

.crm-standard-crud-outer {
    padding-bottom: 40px;
}
