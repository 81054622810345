$primary-color: #0052ff; // 全局主色
$link-color: #0052ff; // 链接色
$success-color: #3ed711; // 成功色
$warning-color: #ffa408; // 警告色
$error-color: #ff001f; // 错误色
$font-size-base: 14px; // 主字号
$heading-color: #000000; // 标题色
$text-color: #000000; // 主文本色
// $text-color: #15161f; // 主文本色
$text-color-secondary: #55575a; // 次文本色
// $text-color-secondary: #6e727a; // 次文本色
$disabled-color: #d1d3de; // 失效色
$border-radius-base: 3px; // 组件/浮层圆角
$border-color-base: #ebecf3; // 边框色
$box-shadow-base: 0 2px 16px rgba(0, 0, 0, 0.1); // 浮层阴影
