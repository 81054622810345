.opt-parses {
    border: 1px solid #ebecf3;
    background-color: white;
    border-radius: 8px;
    .ant-collapse-borderless
        > .ant-collapse-item
        > .ant-collapse-content
        > .ant-collapse-content-box {
        padding-top: 12px;
    }
    // DIRTY & HACK
    .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
        height: auto !important;
    }
    .step {
        padding: 14px;
        .title {
            font-size: 16px;
            color: #15161f;
            font-weight: 500;
            line-height: 16px;
            .desc {
                font-size: 12px;
                color: #afb1bc;
                font-weight: 400;
                line-height: 12px;
                padding-left: 8px;
            }
        }
        .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 13px;
            .step-container {
                flex-grow: 1;
                padding-top: 15px;
                padding-left: 12px;
                padding-right: 8px;
            }
            position: relative;
            .loading-icon {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .shudan-btn {
            padding-top: 10px;
            padding-bottom: 10px;
            text-align: right;
            padding-right: 30px;
        }
    }
    .form {
        .form-inner {
            padding-left: 15px;
            .header {
                font-size: 12px;
                line-height: 12px;
                margin-bottom: 6px;
                .title {
                    font-size: 12px;
                    font-weight: 500;
                    color: #15161f;
                    line-height: 12px;
                }
                .error {
                    padding-left: 15px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #ff001f;
                    line-height: 12px;
                }
            }
            .component-widgets-editalbe {
                position: relative;
                left: -6px;
            }
            .condition-display {
                margin-top: 4px;
                .condition-item {
                    margin-top: 14px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #6e727a;
                    line-height: 14px;
                }
            }
        }
    }
}
