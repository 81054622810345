.customer-m-transform {
    width: 100%;
    min-width: 150px;
    display: inline-block;
    position: relative;

    .form-wrapper {
        padding: 10px 15px;
        background-color: #f7f8fa;
        .ant-form-item {
            margin-bottom: 12px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .ant-select {
        width: 100%;
    }
}
