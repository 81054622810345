.crm-widgets-filter-range-input {
    .value-input {
        .label {
            margin-bottom: 10px;
        }
        .input {
            margin-bottom: 15px;
            & > * {
                width: 100%;
            }
        }
        &:last-of-type {
            .input {
                margin-bottom: 0;
            }
        }
    }
}
