body,
html,
#root,
#root > div {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

body {
    color: #111;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.maimai-performance-page h4 {
    margin-top: 15px;
}

.maimai-space-between {
    display: flex;
    justify-content: space-between;
}

.maimai-row-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.maimai-section {
    padding: 15px 25px;
}

.maimai-loading {
    opacity: 0.7;
    pointer-events: none;
}

.maimai-invilid {
    border-color: lightcoral !important;
}

.maimai-warning {
    color: red;
}

.maimai-info {
    color: blue;
}

.maimai-block {
    padding: 12px !important;
    background-color: #fff;
    /* border: 1px solid #d9d9d9; */
    border-radius: 3px;
}
.maimai-not-block {
    background-color: white;
}

.maimai-no-event {
    pointer-events: none;
}
.maimai-center {
    display: flex;
}
.maimai-center > * {
    margin: auto;
}

.maimai-max-width {
    max-width: 1024px;
    margin: 0 auto;
}

.maimai-enhounce {
    color: #0052ff !important;
    font-weight: bolder;
}

.maimai-subtext {
    font-size: 0.7em;
    color: #999;
}

.ant-input.ant-input-lg {
    &::placeholder {
        font-size: 14px;
    }
    .ant-calendar-range-picker-input {
        &::placeholder {
            font-size: 14px;
        }
    }
}

/* ::-webkit-scrollbar {
    appearance: none;
    width: 6px;
    display: none;
}
::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
} */

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
    background-color: #fff;
}
::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 5px solid #fff;
}
::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
    border: 4px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
    display: none;
}

.standard-drawer-form-wrapper {
    padding-bottom: 40px;
    margin-top: -32px;
    height: 100%;
    .fixed-form-btns {
        padding: 15px;
        text-align: right;
        position: fixed;
        bottom: 0;
        right: 0;
        background-color: white;
        border-radius: 6px;
    }
}

.ant-select-selection {
    user-select: auto !important;
}
