.nav-left-menu {
    padding: 20px 14px !important;
    border: none !important;
    .ant-menu-item {
        height: 72px !important;
        padding: 12px 0px !important;
        margin: 0 !important;
        font-size: 16px !important;
        font-weight: 400;
        &:active {
            background-color: #fff !important;
        }
        &.ant-menu-item-selected {
            background-color: #fff !important;
            & a {
                background-color: #e6f2ff;
            }
            &::after {
                display: none;
            }
        }
        a {
            height: 48px !important;
            line-height: 48px;
            padding: 0 16px !important;
            border-radius: 3px;
            &:active {
                background-color: #e6f2ff;
            }
            .left-menu-icon {
                font-size: 18px;
                margin-right: 16px;
            }
        }
    }
    .ant-menu-submenu-selected {
        color: #15161f;
    }
    .ant-menu-submenu-open {
        & .ant-menu-submenu-title {
            &::after {
                transform: rotate(180deg);
            }
        }
    }
    .ant-menu-submenu {
        .ant-menu-item {
            &:first-of-type {
                margin-top: -12px !important;
            }
            height: 48px !important;
            padding: 0 2px !important;
            line-height: 48px !important;
            a {
                padding-left: 48px !important;
                font-size: 14px;
            }
        }
        .ant-menu-submenu-title {
            // padding: 12px 30px !important;
            padding: 0 16px !important;
            height: 72px !important;
            margin: 12px 0 !important;
            // padding: 0 16px !important;
            font-size: 16px !important;
            font-weight: 400;
            height: 48px !important;
            line-height: 48px !important;
            &::after {
                content: '';
                width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-bottom: 6px solid #d1d3de;
                position: absolute;
                right: 0;
                top: 24px;
                right: 6px;
                transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
                    border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
                    transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
            }
            &:hover {
                &::after {
                    border-bottom-color: #0052ff;
                    border-top-color: #0052ff;
                }
            }
            .ant-menu-submenu-arrow {
                display: none;
            }
            .left-menu-icon {
                font-size: 18px;
                margin-right: 16px;
            }
        }
    }
    &.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
        padding: 0 16px !important;
    }
    &.ant-menu-inline-collapsed {
        & .ant-menu-submenu-title {
            &::after {
                display: none;
            }
        }
    }
}

.ds-menu-content {
    position: relative;
    height: 100%;
    background-color: #fff;
    .ds-menu-body {
        height: 100%;
        overflow: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .left-fixed-trigger {
        position: absolute;
        bottom: 0;
        height: 40px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #eee;
        font-size: 18px;
        cursor: pointer;
    }
    .menu-trigger {
        height: 40px;
        width: 100%;
    }
}
